export class DialogController {
    constructor(dialogSelector, bodySelector, showBackdrop, closeOnEsc, onCloseCallback) {
        this.dialog = "";

        this.dialogSelector = $(`#${dialogSelector}`);
        this.dialogContentSelector = $(`#${bodySelector}`);
        this.onCloseCallback = onCloseCallback;
        
        this.progressSpinner = $("#progress-spinner-container");
        
        const options = {
            backdrop: closeOnEsc ? "static" : (showBackdrop || true),
            keyboard: closeOnEsc || false,
            show: false
        };

        this.dialogSelector.on("hidden.bs.modal", () => {
            this.dialogContentSelector.empty();
            this.progressSpinner.show();
            if (this.onCloseCallback !== undefined && this.onCloseCallback !== null) {
                this.onCloseCallback();
            }
          
        });

        this.dialogSelector.modal(options);
    }
    
    /* The need for this method is hacky. Get rid of it eventually */
    static stripTrailingQuestionmark(url) {
        const lastChar = url.charAt(url.length - 1);
        
        if (lastChar === "?") {
            return url = url.substr(0, url.length - 1);
        }
        
        return url;
    }

    /* The need for this method is hacky. Get rid of it eventually */
    static addTimestampForCachebusting(url) {
        const requestTimestamp = Math.round((new Date()).getTime() / 1000);
        if (url.indexOf("?") !== -1) {
            return `${url}&timestamp=${requestTimestamp}`;
        } else {
            return `${url}?timestamp=${requestTimestamp}`;
        }             
    }

    show(url, callback) {
        url = DialogController.stripTrailingQuestionmark(url);
        url = DialogController.addTimestampForCachebusting(url);
        this.dialogSelector.children(".modal-dialog").css("width", "");
        this.dialogSelector.modal("show");
        this.dialogContentSelector.data("modal-url", url);
        this.dialogContentSelector.load(url, null, () => {
            this.progressSpinner.hide();

            if (typeof callback === "function") {
                callback();
            }
        });

        this.resize(0, 0);
    }

    /**
     * 
     * @param {string} message Fejl besked der vises
     */
    static showAlert(message) {
        $("#eduadm-alert-message").html(message);
        $("#modal-eduadm-alert").modal("show");
    }

    /**
     * 
     * @param {string} formData Serialiseret form
     * @param {string} formUrl Endpoint formdata skal postes til
     * @returns {JQuery.jqXHR<any>} 
     */
    static saveData(formData, formUrl) {
        return $.ajax({
            method: "POST",
            url: formUrl,
            data: formData,
            cache: false,
            success: () => {
                this.hide();
            },
            error: () => {
                this.hide();
            }
        });        
    }

    hide() {
        this.progressSpinner.show();
    }

    cancel(callbackFunction) {
        this.resize(0, 0);
    }

    resize(maxWidth, maxHeight) {
        let width = maxWidth;
        if (width > $(window).width() - 40) {
            width = $(window).width() - 40;
        }
        let height = maxHeight;
        if (height > $(window).height() - 40) {
            height = $(window).height() - 40;
        }
        const left = ($(window).width() - width) / 2;
        const top = ($(window).height() - height) / 2;
    }

    setDialogTitle(title) {
        $("#eduadm-modal-title").text(title);
    }

    /**
     * @param {string} message Besked der vises
     * @param {function} confirmCallback 
     * @param {function} cancelCallback 
     * @returns {void}
     */ 
    static showConfirm(message, confirmCallback, cancelCallback) {
        
        $("#eduadm-confirm-message").text(message);
        
        if (typeof confirmCallback === "function") {
            $("#btnConfirmAccept").click(confirmCallback);
        }
    }
}
