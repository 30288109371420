
export enum FordeltFilter {
    VisAlle,
    VisFordelte,
    VisUfordelte
}

export enum PraktikstedFilter {
    Oplaeringssted,
    Omraade
}