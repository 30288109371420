export const saveTextState = (domElementIdentifier: string, idDataAttributeName: string, localStorageKey: string) => {
    let punktValues = $(domElementIdentifier).map((i,e) => {
        return {
            value : $(e).val(),
            id: $(e).data(idDataAttributeName),
        };
    }).toArray();

    localStorage.setItem(localStorageKey, JSON.stringify(punktValues));
}

export const getAndSetTextValueFromState = (domElementIdentifier: string, idDataAttributeName: string, localStorageKey: string) => {
    let punkts = localStorage.getItem(localStorageKey);
    
    if (punkts === null || punkts === undefined)
        return;
    
    let deStringified = JSON.parse(punkts);
    
    if (deStringified === null || deStringified === undefined)
        return;
    
    $(domElementIdentifier).each((i,e) => {
        let obj = deStringified.filter(x => x.id == $(e).data(idDataAttributeName));
        if (obj !== null && obj !== undefined && obj.length > 0){
            let value = obj[0].value;
            let val = $(e).val() as string;
            if (value !== null && value !== undefined && value.length > val.length){
                $(e).val(value);
            }
        }
    })
}

export const saveCheckboxState = (domElementIdentifier: string, idDataAttributeName: string, typeDataAttributeName: string, localStorageKey: string) => {
    let punktValues = $(domElementIdentifier).map((i,e) => {
        return {
            value : $(e).val(),
            id: $(e).data(idDataAttributeName),
            type: $(e).data(typeDataAttributeName),
            checked: $(e).is(":checked"),
        };
    }).toArray();

    localStorage.setItem(localStorageKey, JSON.stringify(punktValues));
}

export const getAndSetCheckboxValueFromState = (domElementIdentifier: string, idDataAttributeName: string, typeDataAttributeName: string, localStorageKey: string) => {
    let punkts = localStorage.getItem(localStorageKey);

    if (punkts === null || punkts === undefined)
        return;

    let deStringified = JSON.parse(punkts);

    if (deStringified === null || deStringified === undefined)
        return;

    $(domElementIdentifier).each((i,e) => {
        let val = $(e).val();
        let obj = deStringified.filter(x => x.id == $(e).data(idDataAttributeName) && x.type == $(e).data(typeDataAttributeName) && x.value == $(e).data("text"));
        if (obj !== null && obj !== undefined && obj.length > 0){
            let value = obj[0].checked;
            
            if (value === true){
                $(e).prop('checked', true);
            }
        }
    })
}

export const saveUdsagnState = (domElementIdentifier: string, idDataAttributeName: string, typeDataAttributeName: string, localStorageKey: string) => {
    let punktValues = $(domElementIdentifier).map((i,e) => {
        return {
            value : $(e).data("text"),
            id: $(e).data(idDataAttributeName),
            type: $(e).data(typeDataAttributeName),
            checked: $(e).is(":checked"),
        };
    }).toArray();

    localStorage.setItem(localStorageKey, JSON.stringify(punktValues));
}

export const lockAllTextareasAndInput = () => {
    $("textarea").attr("disabled", "disabled");
    $("textarea").attr("readonly", "readonly");
    $("input").attr("readonly", "readonly");
    $("input").attr("disabled", "disabled");
}
