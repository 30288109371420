export const collapseListViewSmallScreensize = () => {
    $("#beskedtraadcontainer").addClass("hide-under-992px");
};

export const expandListViewSmallScreensize = () => {
    $("#beskedtraadcontainer").removeClass("hide-under-992px");
};

export const collapseDetailViewSmallScreensize = () => {
    $("#beskedcontentcontainer").addClass("hide-under-992px");
}

export const expandDetailViewSmallScreensize = () => {
    $("#beskedcontentcontainer").removeClass("hide-under-992px");    
}

export const initCollapseDetailviewButton = () => {
    $("#showListBtn").on("click", () => {
        collapseDetailViewSmallScreensize();
        expandListViewSmallScreensize();
    });
}