import { Chart, PieController, ArcElement, Tooltip, Legend } from "chart.js"

export class PieChart 
{
    Initialiser(dataset, labels, selector, useAnimation = false, title = '') {

        Chart.register(PieController);        
        Chart.register(ArcElement);
        Chart.register(Tooltip);
        Chart.register(Legend);
        let delayed;
        let myChart = new Chart(selector, {
            type: 'pie',
            data: {
                labels: labels,
                datasets: dataset,
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'bottom',
                    },
                    title: {
                        display: true,
                        text: title
                    },
                    tooltip: {
                        enabled: true,
                    }
                }
            },
            animation: useAnimation ? {
                onComplete: () => {
                    delayed = true;
                },
                delay: (context) => {
                    let delay = 0;
                    if (context.type === 'data' && context.mode === 'default' && !delayed) {
                        delay = context.dataIndex * 300 + context.datasetIndex * 100;
                    }
                    return delay;
                },
            } : false,
        });
        
        return myChart;
    }
}
