import {populateBeskedtraadeContainer} from "./beskedtraadList";
import {setContentEmpty} from "./beskedtraadContent";
import {NotificationModule} from "ditmer-embla";

export const initVideregivBeskedtraad = () => {
    
    $("#videregivtraad-form").submit(function (e) {
        e.preventDefault();
        
        const url = $(this).attr("action");
        
        $.post(url, $(this).serialize(), function (data) {
            if (data.success) {
                populateBeskedtraadeContainer();
                setContentEmpty();
                $("#videregivtraad-modal-sm").modal('hide')
                NotificationModule.showSuccessSmall("Tråd videregivet")
            } else {
                NotificationModule.showErrorSmall("Noget gik galt. Prøv igen senere, eller kontakt supporten.")
            }
        });
    });
}
