import {NotificationModule} from "ditmer-embla";

export const enum Area {
    ElevPortal = "ElevPortal",
    Vejlederunivers = "Vejlederunivers"
}

export const setupGemButtons = () => {
    $("#gemForventningsBtn").off().on("click", () => {GemForventningsBesvarelser()});
    $("#gemProevetidsBtn").off().on("click", () => {GemProevetidsBesvarelser()});
    $(".gemDynSamtaleBtn").off().on("click", () => {GemDynamiskeBesvarelser()});
    SetupGemMidtEvalueringBesvarelser();
    SetupGemSlutEvalueringBesvarelser();
}

export const GemForventningsBesvarelser = (showNotification = true, area = Area.Vejlederunivers) => {
    const endpoint = "/samtale/SetFlereForventningsKommentarer";
    const url = buildUrl(area, endpoint);
    
    let forventningspunkter = $(".forventnings-punkt").map((i,e) => {
        return {
            id: $(e).data("punkt-kommentar-id"),
            text: $(e).val(),
        }
    }).toArray();

    $.ajax({
        url: url,
        method: "POST",
        data: 
            {
                models : forventningspunkter,
            },
        }).done(function (e) {
            if (showNotification){
                if (e.success){
                    NotificationModule.showSuccessSmall("Samtale gemt");
                }
                else {
                    NotificationModule.showError("Din kommentar blev ikke gemt", "Bruger du Internet Explorer, eller er du blevet logget ud? Prøv at genopfriske siden ved at trykke F5")
                }
            }
    });
}

export const GemProevetidsBesvarelser = (showNotification = true, area = Area.Vejlederunivers) => {
    const endpoint = "/samtale/SetFlereProevetidsKommentarer";
    const url = buildUrl(area, endpoint);
    
    let proevetidsPunkter = $(".proevetids-punkt").map((i,e) => {
        return {
            id: $(e).data("punkt-kommentar-id"),
            text: $(e).val(),
        }
    }).toArray();

    $.ajax({
        url: url, 
        method: "POST",
        data: 
            {
                models : proevetidsPunkter,
            },
        }).done(function (e) {
            if (showNotification){
                if (e.success){
                    NotificationModule.showSuccessSmall("Samtale gemt");
                }
                else {
                    NotificationModule.showError("Din kommentar blev ikke gemt", "Bruger du Internet Explorer, eller er du blevet logget ud? Prøv at genopfriske siden ved at trykke F5")
            }
        }
    });
}

export const SetupGemMidtEvalueringBesvarelser = () => {
    $("#gemMidtEvalueringBtn").on("click", function(){
        GemEvalueringBesvarelser();
    })
}

export const SetupGemSlutEvalueringBesvarelser = () => {
    $("#gemSlutEvalueringBtn").on("click", function(){
        GemEvalueringBesvarelser();        
    })
}

export const GemEvalueringBesvarelser = (showNotification = true, area = Area.Vejlederunivers) => {
    let harAfsluttendeBemaerkning = $("#afsluttendetext").length > 0;
    let afsluttendeBemaerkning;
    const endpoint = "/Samtale/GemSamletEvalueringSamtale";
    const url = buildUrl(area, endpoint);
    
    
    let elevBesvarelser = $(".evals-punkt-elev").map((i,e) => {
        return {
            id: $(e).data("punkt-kommentar-id"),
            text: $(e).val(),
        }
    }).toArray();

    let vejlederBesvarelser = $(".evals-punkt-vejleder").map((i,e) => {
        return {
            id: $(e).data("punkt-kommentar-id"),
            text: $(e).val(),
        }
    }).toArray();

    let udsagnBesvarelser = $(".udsagncheckbox:checked").map((i,e) => {
        return {
            udsagnid: $(e).data("udsagnid"),
            type: $(e).data("type"),
            value: $(e).data("text"),
        }
    }).toArray();

    let radioButtonBesvarelse = $(".eval-besvarelse:checked").map((i,e) => {
        return {
            id: $(e).data("punkt-id"),
            value: $(e).val(),
            type: $(e).data("type"),
        }
    }).toArray();

    if (harAfsluttendeBemaerkning){
         afsluttendeBemaerkning = {
            samtaleid: $("#afsluttendetext").data("samtale-id"), 
            text: $("#afsluttendetext").val()
        };
    } else {
        afsluttendeBemaerkning = {
            samtaleid: 0,
            text: ''
        };
    }

    $.ajax({
        url: url,
        method: "POST",
        data: 
            {
                udsagn: udsagnBesvarelser,
                vejlederModels: vejlederBesvarelser,
                elevModels: elevBesvarelser,
                radioBesvarelser : radioButtonBesvarelse,
                afsluttendeBemaerkning: afsluttendeBemaerkning,
            },
    }).done(function (e) {
        if (showNotification){
            if (e.success){
                NotificationModule.showSuccessSmall("Samtale gemt");
            }
            else {
                NotificationModule.showError("Noget fejlede!", "Bruger du Internet Explorer, eller er du blevet logget ud? Prøv at genopfriske siden ved at trykke F5")
            }
        }
    });
}

export const GemDynamiskeBesvarelser = (showNotifications = true, area = Area.Vejlederunivers) => {
    const endpoint = "/samtale/GemDynamiskeBemaerkninger";
    const url = buildUrl(area, endpoint);
    
    let dynBesvarelser = $(".dynamisk-bemaerkning").map((i,e) => {
        return {
            punktid: $(e).data("punktid"),
            value: $(e).val(),
        }
    }).toArray();

    $.ajax({
        url: url,
        method: "POST",
        data: 
            {
                punkter: dynBesvarelser
            },
    }).done(function (e) {
        if (showNotifications) {
            if (e.success) {
                NotificationModule.showSuccessSmall("Samtale gemt");
            } else {
                NotificationModule.showError("Noget fejlede!", "Bruger du Internet Explorer, eller er du blevet logget ud? Prøv at genopfriske siden ved at trykke F5")
            }
        }
    });
}

const buildUrl = (area: Area, endpoint: string): string => {
    return `/${area}${endpoint}`;
}