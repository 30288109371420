export class GlobalLoader {
    
    static showLoader(msg) {

        const $loader = $("#GlobalLoader");
        const $loaderMsg = $("#LoaderMsg");
        
        if (msg != undefined && msg.length > 0) {
            $loaderMsg.text(msg);
            $loaderMsg.css("display", "block");
        } else {
            $loaderMsg.css("display", "none");
        }

        if (!$("body").hasClass("lock-y")) {
            $("body").addClass("lock-y");
        }

        $loader.fadeIn("fast");
    }

    static hideLoader(callback) {

        const $loader = $("#GlobalLoader");
        const $loaderMsg = $("#LoaderMsg");

        $loader.fadeOut("fast", callback);
        $loaderMsg.text("");

        if ($("body").hasClass("lock-y")) {
            $("body").removeClass("lock-y");
        }
    }
}