import {NotificationModule} from "ditmer-embla";
import {getFiles, resetFiles, initFilePicker} from "./filepicker";
import {populateBeskedtraadeContainer} from "./beskedtraadList";
import {initVideregivBeskedtraad} from "./videregivtraad";
import {getBesvarVal, initBesvarField, initCkEditor} from "./besvarfield";
import {GlobalLoader} from "../Shared/GlobalLoader";
import {
    collapseListViewSmallScreensize,
    expandDetailViewSmallScreensize,
    initCollapseDetailviewButton
} from "./responsiveUtilities";

export const initBeskedtraadContent = () => {
    resetFiles();
    initBesvarField();
    initSendBtn();
    initFilePicker();
    initSletBtn();
    initVideregivTraadBtn();
    initMarkerSomUlaestBtn();
    expandDetailViewSmallScreensize();
    collapseListViewSmallScreensize();
    initCollapseDetailviewButton();
    initNotifications();
    initCkEditor();
} 

const initSendBtn = () => {
    $("#sendBtn").off().on("click", function() {
        
        const val = getBesvarVal();

        if (val === "") {
            NotificationModule.showInfoSmall("Du kan ikke sende en tom besked");
            return;
        }

        const url = $(this).data("url");
        const traadID = $(this).data("beskedtraadid");
        const reloadUrl = $(this).data("reloadurl");

        // Create a FormData object to handle the files
        const formData = new FormData();

        // Append each file to the FormData object
        getFiles().forEach(fileModel => {
            formData.append('beskedfiler', fileModel.file);
        });
        formData.append('text', val.toString());
        formData.append('beskedtraadid', traadID);

        ($("#sendmail") !== undefined && $("#sendmail").length !== 0 && !$("#sendmail").hasClass("hidden")) 
            ? formData.append('SendEmail', "true") 
            : formData.append('SendEmail', "false");
        
        ($("#sendsms") !== undefined && $("#sendsms").length !== 0 && !$("#sendsms").hasClass("hidden")) 
            ? formData.append('SendSms', "true") 
            : formData.append('SendSms', "false");

        $("#sendBtn").off(); //Forhindrer dobbeltklik
        GlobalLoader.showLoader("Sender besked...")
        
        $.ajax({
            url: url,
            type: 'POST',
            data: formData,
            contentType: false,
            processData: false,
            success: function (data) {
                GlobalLoader.hideLoader();
                const indholdDiv = $("#beskedcontentcontainer");
                indholdDiv.html(spinnerHtmlContent());
                $.get(reloadUrl, (html) => {
                    indholdDiv.html(html);
                    initBeskedtraadContent();
                });
            },
            error: function () {
                NotificationModule.showErrorSmall("Der skete en fejl under afsendelse af beskeden");
            }
        });
    })
}

const initSletBtn = () => {
    $("#sletBtn").on("click", function(e) {
        e.preventDefault();
        const url = $(this).attr("href");

        $.post(url, function() {
            NotificationModule.showSuccessSmall("Besked slettet")
            populateBeskedtraadeContainer();
            setContentEmpty();
        });
    })
}

const initVideregivTraadBtn = () => {
    $("#videregivtraadBtn").on("click", function(e) {
        e.preventDefault();
        
        const url = $(this).attr("href");

        $.get(url, (html) => {
            $("#modalcontainer").html(html);
            $("#videregivtraad-modal-sm").modal('show')
            initVideregivBeskedtraad();
        })
    })
}

const initMarkerSomUlaestBtn = () => {
    $("#markerSomUlaestBtn").on("click", function(e) {
        e.preventDefault();
        const url = $(this).attr("href");

        $.post(url, function() {
            NotificationModule.showSuccessSmall("Tråd markeret som ulæst")
            populateBeskedtraadeContainer();
        });
    })
}

const initNotifications = () => {

    $(".notificationBtn").off().on("click", function(e){
        e.preventDefault();
        $(this).children("svg").toggleClass("hidden");
    })
}


export const spinnerHtmlContent = () => {
    return `<div class="d-flex align-items-center justify-content-center height-100 width-100">
                <svg class="embla-icon progress-with-animation" id="progress-spinner" aria-hidden="true" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <use xlink:href="/wwwroot/dist/icons/sprite.symbol.svg#progress"></use>
                </svg>
            </div>`;
}

export const setContentEmpty = () => {
    $("#beskedcontentcontainer").html("<div class=\"width-100 d-flex align-items-center justify-content-center height-70vh\">\n" +
        "    <div class=\"d-flex align-items-center flex-column\">\n" +
        "        <div class=\"illustration-wrapper\" style=\"background: #EBF0F4; width: 128px; height:  128px;\">\n" +
        "            <svg class=\"embla-illustration\" aria-hidden=\"true\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" style=\"color: #555; fill: #555;\">\n" +
        "                <use xlink:href=\"/dist/illustrations/sprite.symbol.svg#chatting-on_project\"></use>\n" +
        "            </svg>\n" +
        "            <div class=\"shade\"></div>\n" +
        "        </div>\n" +
        "        <h3>Vælg en besked for at se indhold</h3>\n" +
        "    </div>\n" +
        "</div>");
}