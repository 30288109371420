import {GlobalLoader} from "../Shared/GlobalLoader";
import {initBeskedtraadContent, spinnerHtmlContent} from "./beskedtraadContent";
import {active} from "sortablejs";

const enum FolderEnum {
    Indbakke = 1,
    Udbakke = 2,
}

let currentFilter = null;
let currentPage = 1;
let query = "";
let currentFolder = FolderEnum.Indbakke;

export const populateBeskedtraadeContainer = () => {
    const container = $("#beskedtraadecontent");
    container.html(getSpinnerHtml());
    
    const url = container.data("url") + `?page=${currentPage}&query=${query}&filter=${currentFilter}&folder=${currentFolder}`;

    $.get(url, (html) => {
        container.html(html);
        initBeskedClick();
        initSearchField();
        initFilterBtn();
        initPaging();
        initPrePickedBesked();
        initIndbakkeUdbakkeControls();
    })
}

export const initFilterBtn = () => {

    $(".filterBtn").off().on("click", function(){
        const searchBar = $("#iconRightInput"); 
        const filterType = $(this).data("filtertype");
        searchBar.data("filtertype", filterType);
        currentFilter = filterType;

        if (+filterType > 1){
            if (!$("#dropdown-menu-primary").hasClass("btn-primary"))
                $("#dropdown-menu-primary").addClass("btn-primary")
            
        } else {
            $("#dropdown-menu-primary").removeClass("btn-primary")
        }
        
        $(".filterBtn").children("svg").addClass("hidden");
        $(this).children("svg").removeClass("hidden");
        populateBeskedtraadeContainer();
    })
}

const initPrePickedBesked = () => {
    if ($("#sendBtn").data("beskedtraadid") != null) {
        $(".besked[data-id=" + $("#sendBtn").data("beskedtraadid") + "]").addClass("active");
    }
}

const initSearchField = () => {
    $("#beskedsearch").off().on("keyup", delay((e) => {
        if (isNonUnicodeChar(e.keyCode))
            return;
        
        query = $("#beskedsearch").val().toString();
        populateBeskedtraadeContainer();
    }, 300))
}

const initIndbakkeUdbakkeControls = () => {
    $("[data-folder]").off().on("click",function () {
        $(this).removeClass("btn-default");
        $(this).siblings().removeClass("btn-primary");
        $(this).siblings().addClass("btn-default");
        $(this).addClass("btn-primary");
        const newFolder = $(this).data("folder") as FolderEnum;
        
        if (newFolder != currentFolder){
            currentFolder = newFolder;
            populateBeskedtraadeContainer();
        }
    });
}

const initPaging = () => {
    $(".pagebtn").off().on("click", function() {
        currentPage = $(this).data("page");
        populateBeskedtraadeContainer();
    })
}

const initBeskedClick = () => {

    $(".besked,#opretbeskedBtn").off().on("click", function(e) {

        $(this).addClass("active");
        document.getSelection().removeAllRanges();
        
        if (e.ctrlKey || e.shiftKey) {
            e.preventDefault();
            if (e.shiftKey)
                handleShiftClick(this);
            
            handleMultipleBeskedClick();
        } else {
            $(".besked").removeClass("active");
            $(this).addClass("active");
            handleSingleBeskedClick(this);
        }
    })
}

const handleSingleBeskedClick = (element) => {
    const url = $(element).data("url");

    const indholdDiv = $("#beskedcontentcontainer");
    showSpinner();
    $.get(url, (html) => {
        indholdDiv.html(html);
        GlobalLoader.hideLoader();
        initBeskedtraadContent();
        $(element).find(".ulaest-icon").remove();
    })
}

const handleShiftClick = (clickedElement) => {
    const messages = $(".besked");
    const clickedIndex = messages.index(clickedElement);

    let firstSelectedIndex = messages.index($(".besked.active").first());
    let lastSelectedIndex = messages.index($(".besked.active").last());

    if (firstSelectedIndex === -1) {
        // If no message is selected, treat the clicked message as both the first and last selected
        firstSelectedIndex = clickedIndex;
        lastSelectedIndex = clickedIndex;
    }

    // Calculate start and end index based on the positions of the clicked message 
    // relative to the first and last selected messages
    const startIndex = Math.min(clickedIndex, firstSelectedIndex);
    const endIndex = Math.max(clickedIndex, lastSelectedIndex);

    selectedBeskeder = []; // Reset the selection

    // Select all messages in the range and update 'selectedBeskeder'
    messages.each(function(index) {
        if (index >= startIndex && index <= endIndex) {
            $(this).addClass("active");
            selectedBeskeder.push(parseInt($(this).data("id")));
        } else {
            $(this).removeClass("active");
        }
    });

}

let selectedBeskeder = [];
const handleMultipleBeskedClick = () => {
    
    selectedBeskeder = [];
    $(".besked.active").each(function() {
        selectedBeskeder.push($(this).data("id"));
    });
    
    const indholdDiv = $("#beskedcontentcontainer");
    let url = $("#multiplebesked").data("url") + "?" + selectedBeskeder.map((id, index) => `ids[${index}]=${id}`).join('&');

    showSpinner();
    $.get(url, (html) => {
        indholdDiv.html(html);
        GlobalLoader.hideLoader();
        initBeskedtraadContent();
        $(this).find(".ulaest-icon").remove();

        $("#markerSomUlaestBtn").off().on("click", function() {
            const url = $(this).data("url");
            $.post(url, {traadIds: selectedBeskeder}, () => {
                populateBeskedtraadeContainer();
            })
        });

        $("#sletBtn").off().on("click", function() {
            const url = $(this).data("url");
            $.post(url, {traadIds: selectedBeskeder}, () => {
                populateBeskedtraadeContainer();
            })
        });
    });
}

const showSpinner = () => {
    if (window.innerWidth < 768) {
        GlobalLoader.showLoader("Vent venligst...");
    }
    else {
        const indholdDiv = $("#beskedcontentcontainer");
        indholdDiv.html(spinnerHtmlContent());
    }
}

const getSpinnerHtml = () => {
    return `<div class="d-flex align-items-center justify-content-center height-100 width-100">
                <svg class="embla-icon progress-with-animation" id="progress-spinner" aria-hidden="true" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <use xlink:href="/wwwroot/dist/icons/sprite.symbol.svg#progress"></use>
                </svg>
            </div>`;
}

const delay = (callback, ms) => {
    let timer = 0;
    return function () {
        const context = this, args = arguments;
        clearTimeout(timer);
        timer = window.setTimeout(function () {
            callback.apply(context, args);
        }, ms || 0);
    };
};

const isNonUnicodeChar = (keycode: number) => {
    const nonCharKeyCodes = [
        13, // Enter
        16, // Shift
        17, // Ctrl
        18, // Alt
        20, // Caps lock
        37, // Left arrow
        38, // Up arrow
        39, // Right arrow
        40, // Down arrow
    ];
    
    return nonCharKeyCodes.includes(keycode);
}
