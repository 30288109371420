import { useEffect, useState } from 'react'

export const useToggleList = <T, K>(toKey: (t: T) => K): [Map<K, T>, (x: T) => void, (t: T) => boolean, () => void] => {

    const [[state, isOn], setState] = useState<[Map<K, T>, (t: T) => boolean]>([new Map(), (t: T) => false]);

    const toggleItem = (item: T) => {
        const asKey = toKey(item);
        setState(([c, _]) => {
            if (c.has(asKey)) {
                c.delete(asKey)
            } else {
                c.set(asKey, item)
            }
            return [new Map(c), (t: T) => c.has(toKey(t))]; //If someone only depends on isOn, it won't redraw unless it is part of the state. Hence, we make it part of the state.
        });
    };

    const clearAll = () => setState([new Map(), (t: T) => false]);

    return [state, toggleItem, isOn, clearAll];
}

export const useToggleSingleList = <T>(): [T, (x: T) => void, (t: T) => boolean, () => void] => {

    const [[state, isOn], setState] = useState<[T, (t: T) => boolean]>([null, (t: T) => false]);

    const toggleItem = (item: T) => {
        setState(([c, _]) => {
            return [c == item ? null : item, t => c != item && t == item];
        });
    };

    const clearAll = () => setState([null, (t: T) => false]);

    return [state, toggleItem, isOn, clearAll];
}