import { ModalForm } from "../Shared/ModalForm";
import { setupDatePicker } from "../Shared/GlobalSetup";

export const visLoenOgAnsaettelseSetup = () => {
    setupDatePicker();
    const modalForm = new ModalForm();
    modalForm.initialize('#formVisLoenOgAnsaettelseForhold', 'Gemmer ændringer', '#formValidation');

    HandleLoenSetup();
};

export const HandleLoenSetup = () => {
    HandleLaerlingeloenRefusion();
    showHideOptionsOnSelectValue();
    showHideRadioAreas();
    $(".loentypeRadio").on("change", function() {
        showHideRadioAreas();
    });
    initIndividuelPension();
    $('[data-toggle="tooltip"]').tooltip();
}

function HandleLaerlingeloenRefusion() {
    
    $("#voksenlaerlingeRefusionDropdown").on("change", function(){
        showHideOptionsOnSelectValue();
    });
}

const initIndividuelPension = () => {
    const pensionInput = $("#IndividuelPension");

    let individuelPensionTemp = pensionInput.val();
    if (pensionInput.val() !== ""){
        let chkbox = $("#IndividuelPensionChkBox");
        chkbox.val(true);
        chkbox.prop("checked", true);
        $("#individuelpension-div").show();
    }

    $("#IndividuelPensionChkBox").on("change", function() {
        if (this.checked) {
            pensionInput.val(individuelPensionTemp);
            $("#individuelpension-div").show();
        }
        else {
            $("#individuelpension-div").hide();
            individuelPensionTemp = pensionInput.val();
            pensionInput.val("");
        }
    })
}

const showHideOptionsOnSelectValue = () => {
    const val = $("#voksenlaerlingeRefusionDropdown").val()
    $(".voksenlaerlinge30").hide();
    $(".voksenlaerlinge45").hide();
    
    if (val == null)
        return;
    
    if (val.includes("30")){
        $(".voksenlaerlinge30").show();
    }
    else if (val.includes("45")){
        $(".voksenlaerlinge45").show();
    }
}

const showHideRadioAreas = () => {
    $(".radioArea").hide();
    
    if ($("input:radio[value='1']:checked").val()){
        $("#elevloenRadioTaget").show();
    }
    else if ($("input:radio[value='2']:checked").val()){
        $("#voksenelevRadioTarget").show();
    }
    else if ($("input:radio[value='3']:checked").val()){
        $("#voksenlaerlingeRadioTarget").show();
    }
    else if ($("input:radio[value='4']:checked").val()){
        $("#individuelLoenRadioTarget").show();
    }
}