import { isRegularExpressionLiteral } from "typescript";


export const groupBy = <K, T>(list: T[], keyGetter: (t: T) => K): Map<K, T[]> => { //https://stackoverflow.com/questions/14446511/most-efficient-method-to-groupby-on-an-array-of-objects
    const map = new Map();
    list.forEach((item) => {
          const key = keyGetter(item);
          const collection = map.get(key);
          if (!collection) {
              map.set(key, [item]);
          } else {
              collection.push(item);
          }
    });
    return map;
  };

export const sum = <T>(list: T[], toNumber: (t: T) => number) => list.reduce((a, b) => a + toNumber(b), 0);


export const toMap = <K, T>(list: T[], keyGetter: (t: T) => K) => {
    const map = new Map<K, T>();
    list.forEach((item) => {
          const key = keyGetter(item);
          map.set(key, item);
    });
    return map;
}