import moment from "moment";
import "bootstrap-datepicker";
import "bootstrap-datepicker-css";
import "../../../node_modules/jquery-timepicker/jquery.timepicker.js";
import "../../../node_modules/jquery-timepicker/jquery.timepicker.css";


import { SetDataTable } from "../Shared/SharedFunctions";
import {DatetimePickerModule, DatetimePickerTypeEnum, FileUploadModule, NotificationModule} from "ditmer-embla";

let didGlobalSetupRun = false;

$(() => {
    globalSetup();
});

export const setupDatePicker = () => {
    $(".datepicker").datepicker({
        format: "dd-mm-yyyy",
        autoclose: true,
        orientation: "bottom",
        language: "da",
        weekStart: 1,
        calendarWeeks: true,
    });
    setupDatePickerValidation();
};

export const setupTimePicker = () => {
    $(".timepicker").timepicker({
        timeFormat: 'HH:mm',
        interval: 15,
        startTime: '06:00',
        dynamic: false,
        dropdown: true,
        scrollbar: true
    });
};

export const setupDateTimePicker = () => {

    $(".datetimepicker").each((i,e) => {
        const datetimePicker = new DatetimePickerModule(e,
            {
                calenderTypeOption: DatetimePickerTypeEnum.DateAndTime,
                dateFormat: "dd-mm-yyyy",
                lang: "da-DK",
            });

        const val = $(e).val();
        if (!(val === undefined || val === "")){
            const momentVal = moment(val, "DD/MM/YYYY HH:mm");
            datetimePicker.setValue(momentVal);
        }

        $(e).removeAttr("readonly");
    });
}; 

export const setupDatePickerOnChange = (selector, onChange) => {
    $(selector).datepicker({
        format: "dd-mm-yyyy",
        autoclose: true,
        orientation: "bottom",
        language: "da",
        weekStart: 1,
        calendarWeeks: true,
    }).on("changeDate", function(e){ onChange(e) });
    setupDatePickerValidation();
};

const setupFilePicker = () => {
    new FileUploadModule(".inputfile");
};

const updateBreadcrumbs = () => {
    $("#breadcrumb-valgt-udd").text($("#breadcrumb-valgt-udd-hf").val());  
    $("#breadcrumb-valgt-org").text($("#breadcrumb-valgt-org-hf").val());  
};

const setupDatePickerValidation = () => {
    jQuery.validator.addMethod("date", function (value, element) {
        if (value === "") {
            return this.optional(element);
        }
        
        const momentDate = moment(value, ["DD-MM-YYYY HH:mm", "DD-MM-YYYY"], true);
        
        return this.optional(element) || momentDate.isValid();
    });
};

export const globalSetup = () => {

    if(didGlobalSetupRun) {
        return;
    }
    
    updateBreadcrumbs();
    setupFilePicker();
    jqueryDecimalValidationSetup();
    setupDatePicker();
    
    // Notifications setup
    const notificationValue = $("#ToastNotification").val();
    const errorNotificationValue = $("#ErrorToastNotification").val();
    
    // Success notifications
    if (notificationValue) {
        NotificationModule.showSuccess(notificationValue, "");
    }
    
    // Error notifications
    if (errorNotificationValue) {
        NotificationModule.showError(errorNotificationValue, "");
    }

    // Datatables setup
    makeDateColumnsSortable();
    SetDataTable("#sortedTable", null);

    // Bootstrap/Embla tooltips setup
    $('[data-toggle="tooltip"]').tooltip();

    submitLinksAsHttpPostListener();
    
    didGlobalSetupRun = true;
};

const makeDateColumnsSortable = () => {
    const datePattern = new RegExp(/(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{2,4}/);

    $('table').find('tr').each(function (i, e) {
        const tds = $(this).find('td');
        $(tds).each(function (i, td) {
            const result = datePattern.exec($(td).html());
            if (isDateColumn(result)) {
                const date = getDateFromRegex(result);
                prependSortableDateInTd(td, date);
            }
        });
    });
};

const isDateColumn = (result) => {
    return result !== null && result.length > 0;
};

const prependSortableDateInTd = (td, date) => {
    $(td).prepend(`<div class="hidden">${date}</div>`);
};

const getDateFromRegex = (result) => {
    const dateSplitChar = result[0].indexOf('/') > 0 ? '/' : '-';
    const date = result[0].split(dateSplitChar);
    return `${date[2]}${date[1]}${date[0]}`;
};

const submitLinksAsHttpPostListener = () => {
    $(".httpPostLink").click(function () {
        var form = $('#__AjaxAntiForgeryForm');
        form.attr("action", $(this).data('url'));
        form.submit();
    });
};

const jqueryDecimalValidationSetup = () => {
    $.validator.methods.range = function (value, element, param) {
        var globalizedValue = value.replace(",", ".");
        return this.optional(element) || (globalizedValue >= param[0] && globalizedValue <= param[1]);
    }

    $.validator.methods.number = function (value, element) {
        return this.optional(element) || /^-?(?:\d+|\d{1,3}(?:[\s\.,]\d{3})+)(?:[\.,]\d+)?$/.test(value);
    }
}