import { useCallback, useEffect, useState } from "react";


export function useGet<T, P>(url: string, initial: T, delay: boolean = false): [T, (p: P) => void] {

    const [{ state, get, parameters }, setState] = useState<{state: T, get: boolean, parameters}>({ state: initial, get: !delay, parameters: undefined });
    
    useEffect(() => {
        if(get){
            console.log(url);
            fetch(url + parseParameters())
                .then(d => d.json())
                .then(d => setState({ state: JSON.parse(d), get: false, parameters: undefined }));
        }
    }, [get]);

    const parseParameters = () => {
        if(parameters == undefined){
            return "";
        }
        const keys = Object.keys(parameters);
        if(keys.length == 1 && keys[0] == "id"){
            return "/" + parameters[keys[0]];
        }
        const values = keys.map(key => key + "=" + parameters[key].toString()).join("&");
        return "?" + values;
    }

    return [state, (p?: P) => setState(({ state, get }) => ({ state, get: true, parameters: p }))];
}

export function usePost<T>(url: string): [(data: T) => void, { result: boolean, error: string }, boolean] {

    const [posting, setPosting] = useState(false);
    const [result, setResult] = useState({ result: true, error: "" });

    const post = useCallback((data: T) => {
        setPosting(true);
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(d => d.json()).then(d => { 
            setPosting(false);
            setResult(JSON.parse(d)) 
        });
    }, [url]);

    return [post, result, posting];
}