import {
    Chart,
    LineController,
    Filler,
    Tooltip,
    PointElement,
    LineElement,
    Title,
    CategoryScale,
    LinearScale,
    BarElement,
    Ticks
} from "chart.js"

export class LineChart
{
    //Eksempel på "dataset" objekter: 
    // label: 'Dataset',
    // data: generateData(),
    // borderColor: Utils.CHART_COLORS.red,
    // backgroundColor: Utils.transparentize(Utils.CHART_COLORS.red),
    // fill: false
    
    //Labels er bare et array af stringe
    
    //yTickLabelAppend kan indsætte en tekst efter hver tick, f.eks. 5%, 10%, 15%... 
    Initialiser(dataset, labels, yaxismin, yaxismax, selector, ticksize, delay = 100, title = "", ytickLabelAppend = "", xtickLabelAppend = "") {
        
        Chart.register(LineController);
        Chart.register(CategoryScale);
        Chart.register(LinearScale);
        Chart.register(BarElement);
        Chart.register(Tooltip);
        Chart.register(PointElement);
        Chart.register(LineElement);
        Chart.register(Filler);
        Chart.register(Title);
        let delayed;
        let myChart = new Chart(selector, {
            type: 'line',
            data: {
                labels: labels,
                datasets:  dataset,
            },
            options: {
                plugins: {
                    filler: {
                        propagate: false,
                    },
                    title: {
                        display: true,
                        text: title,
                        padding: {
                            top: 10,
                            bottom: 10
                        },
                        font: {
                            size: "20pt",
                        }
                    }
                },
                interaction: {
                    intersect: false,
                },
                scales: {
                    y: {
                        suggestedMin: yaxismin,
                        suggestedMax: yaxismax,
                        ticks : {
                            stepSize : ticksize,
                            callback: function(value, index, values) {
                                return value + ytickLabelAppend;
                            }
                        }
                    }
                },
                animation: {
                    onComplete: () => {
                        delayed = true;
                    },
                    delay: (context) => {
                        if (context.type === 'data' && context.mode === 'default' && !delayed) {
                            
                            delay = context.dataIndex * delay + context.datasetIndex * delay;
                        }
                        return delay;
                    },
                },
                pointBackgroundColor: '#fff',
                radius: 5,
            }
        });

        myChart.data.datasets.forEach(dataset => {
            dataset.fill = 'start';
        });
        
        return myChart;
    }
}
